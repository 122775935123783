<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-form @submit.prevent>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('Naziv')"
                label-for="naziv"
              >
                <b-form-input
                  id="naziv"
                  v-model="naziv"
                  :placeholder="$t('naziv')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Kategorija')"
                label-for="kategorija"
              >
                <v-select
                  v-model="kategorija"
                  label="text"
                  :options="kategorije"
                  :placeholder="$t('IzaberiteKategoriju')"
                />
              </b-form-group>
            </b-col>
            <!-- submit and reset -->
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="snimiSablon"
              >
                {{ $t('Sacuvaj') }}
              </b-button>
              <router-link :to="{ name: 'autoskola-cene-obuke' }">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  {{ $t('Otkazi') }}
                </b-button>
              </router-link>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-card>
      <b-table-simple
        hover
        small
        caption-top
        responsive
      >
        <colgroup><col><col></colgroup>
        <colgroup><col><col><col></colgroup>
        <colgroup><col><col></colgroup>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>{{ $t('RedniBroj') }}</b-th>
            <b-th>{{ $t('Opis') }}</b-th>
            <b-th>{{ $t('JedinicaMere') }}</b-th>
            <b-th>{{ $t('CenaJedinice') }}</b-th>
            <b-th>{{ $t('Kolicina') }}</b-th>
            <b-th>{{ $t('Ukupno') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>1.</b-td>
            <b-td>
              <span style="font-weight:bold;">{{ $t('TeorijskaObuka') }}</span>
            </b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="teorijska_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="teorijska_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupnoTeorijskaObuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>2.</b-td>
            <b-td>{{ $t('DodatnaTeorijskaObuka') }}</b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="dodatna_teorijska_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="dodatna_teorijska_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_dodatna_teorijska_obuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>3.</b-td>
            <b-td>{{ $t('DopunskaTeorijskaObuka') }}</b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="dopunska_teorijska_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="dopunska_teorijska_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_dopunska_teorijska_obuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>4.</b-td>
            <b-td>{{ $t('PolaganjeTeorijskogIspita') }}</b-td>
            <b-td>{{ $t('kom') }}</b-td>
            <b-td>
              <b-form-input
                v-model="polaganje_teorijskog_ispita_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="polaganje_teorijskog_ispita_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_polaganje_teorijskog_ispita"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>5.</b-td>
            <b-td>
              <span style="font-weight:bold;">{{ $t('PrakticnaObuka') }}</span>
            </b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="prakticna_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="prakticna_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_prakticna_obuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>6.</b-td>
            <b-td>{{ $t('DodatnaPrakticnaObuka') }}</b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="dodatna_prakticna_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="dodatna_prakticna_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_dodatna_prakticna_obuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>7.</b-td>
            <b-td>{{ $t('DopunskaPrakticnaObuka') }}</b-td>
            <b-td>{{ $t('n/cas') }}</b-td>
            <b-td>
              <b-form-input
                v-model="dopunska_prakticna_obuka_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="dopunska_prakticna_obuka_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_dopunska_prakticna_obuka"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>8.</b-td>
            <b-td>{{ $t('PolaganjePrakticnogIspita') }}</b-td>
            <b-td>{{ $t('kom') }}</b-td>
            <b-td>
              <b-form-input
                v-model="polaganje_prakticnog_ispita_cena"
                size="sm"
                :placeholder="$t('CenaJedinice')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="polaganje_prakticnog_ispita_kolicina"
                size="sm"
                :placeholder="$t('Kolicina')"
              />
            </b-td>
            <b-td>
              <b-form-input
                v-model="ukupno_polaganje_prakticnog_ispita"
                size="sm"
                type="number"
                :placeholder="$t('Ukupno')"
              />
            </b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td
              colspan="6"
              variant="secondary"
              class="text-right"
            >
              {{ $t('Svega') }}:
              <b>{{ iznos }}</b>
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCardBody,
  BCard,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCardBody,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: 0,
      cene: {},
      naziv: '',
      kategorije: [],
      kategorija: {},
      hideHeader: true,
      teorijska_obuka_cena: 0,
      teorijska_obuka_kolicina: 0,
      dodatna_teorijska_obuka_cena: 0,
      dodatna_teorijska_obuka_kolicina: 0,
      dopunska_teorijska_obuka_cena: 0,
      dopunska_teorijska_obuka_kolicina: 0,
      polaganje_teorijskog_ispita_cena: 0,
      polaganje_teorijskog_ispita_kolicina: 0,

      prakticna_obuka_cena: 0,
      prakticna_obuka_kolicina: 0,
      dodatna_prakticna_obuka_cena: 0,
      dodatna_prakticna_obuka_kolicina: 0,
      dopunska_prakticna_obuka_cena: 0,
      dopunska_prakticna_obuka_kolicina: 0,
      polaganje_prakticnog_ispita_cena: 0,
      polaganje_prakticnog_ispita_kolicina: 0,
      iznosRate: 0,
      datumRate: '',
    }
  },
  computed: {
    ukupnoTeorijskaObuka() {
      return this.teorijska_obuka_cena * this.teorijska_obuka_kolicina
    },
    ukupno_dodatna_teorijska_obuka() {
      return (
        this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina
      )
    },
    ukupno_dopunska_teorijska_obuka() {
      return (
        this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina
      )
    },
    ukupno_polaganje_teorijskog_ispita() {
      return (
        this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina
      )
    },
    ukupno_prakticna_obuka() {
      return this.prakticna_obuka_cena * this.prakticna_obuka_kolicina
    },
    ukupno_dodatna_prakticna_obuka() {
      return (
        this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina
      )
    },
    ukupno_dopunska_prakticna_obuka() {
      return (
        this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina
      )
    },
    ukupno_polaganje_prakticnog_ispita() {
      return (
        this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina
      )
    },
    iznos() {
      return (
        this.teorijska_obuka_cena * this.teorijska_obuka_kolicina + this.dodatna_teorijska_obuka_cena * this.dodatna_teorijska_obuka_kolicina + this.dopunska_teorijska_obuka_cena * this.dopunska_teorijska_obuka_kolicina + this.polaganje_teorijskog_ispita_cena * this.polaganje_teorijskog_ispita_kolicina + this.prakticna_obuka_cena * this.prakticna_obuka_kolicina + this.dodatna_prakticna_obuka_cena * this.dodatna_prakticna_obuka_kolicina + this.dopunska_prakticna_obuka_cena * this.dopunska_prakticna_obuka_kolicina + this.polaganje_prakticnog_ispita_cena * this.polaganje_prakticnog_ispita_kolicina
      )
    },
  },
  mounted() {
    this.pokupiListu()
    this.$http.get('/autoskole-administratori/kategorije').then(response => {
      Object.entries(response.data).forEach(value => {
        this.kategorije.push({ value: value[1].id, text: `${value[1].naziv}` })
      })
    })
  },
  methods: {
    pokupiListu() {
      if (typeof this.$route.params.id !== 'undefined') {
        this.$http.get(`/autoskole-administratori/cene-obuke/${this.$route.params.id}`).then(response => {
          this.cene = response.data
          this.kategorija = { value: this.cene.kategorija_id, text: this.cene.kategorija_naziv }
          this.naziv = this.cene.naziv === '' ? this.$i18n.t('NijeUnet') : this.cene.naziv
          this.teorijska_obuka_cena = this.cene.teorijska_obuka_cena
          this.teorijska_obuka_kolicina = this.cene.teorijska_obuka_kolicina
          this.dodatna_teorijska_obuka_cena = this.cene.dodatna_teorijska_obuka_cena
          this.dodatna_teorijska_obuka_kolicina = this.cene.dodatna_teorijska_obuka_kolicina
          this.dopunska_teorijska_obuka_cena = this.cene.dopunska_teorijska_obuka_cena
          this.dopunska_teorijska_obuka_kolicina = this.cene.dopunska_teorijska_obuka_kolicina
          this.polaganje_teorijskog_ispita_cena = this.cene.polaganje_teorijskog_ispita_cena
          this.polaganje_teorijskog_ispita_kolicina = this.cene.polaganje_teorijskog_ispita_kolicina
          this.prakticna_obuka_cena = this.cene.prakticna_obuka_cena
          this.prakticna_obuka_kolicina = this.cene.prakticna_obuka_kolicina
          this.dodatna_prakticna_obuka_cena = this.cene.dodatna_prakticna_obuka_cena
          this.dodatna_prakticna_obuka_kolicina = this.cene.dodatna_prakticna_obuka_kolicina
          this.dopunska_prakticna_obuka_cena = this.cene.dopunska_prakticna_obuka_cena
          this.dopunska_prakticna_obuka_kolicina = this.cene.dopunska_prakticna_obuka_kolicina
          this.polaganje_prakticnog_ispita_cena = this.cene.polaganje_prakticnog_ispita_cena
          this.polaganje_prakticnog_ispita_kolicina = this.cene.polaganje_prakticnog_ispita_kolicina
        })
      }
    },
    snimiSablon() {
      const data = {
        naziv: this.naziv,
        kategorija_id: this.kategorija.value,
        teorijska_obuka_cena: this.teorijska_obuka_cena,
        teorijska_obuka_kolicina: this.teorijska_obuka_kolicina,
        dodatna_teorijska_obuka_cena: this.dodatna_teorijska_obuka_cena,
        dodatna_teorijska_obuka_kolicina: this.dodatna_teorijska_obuka_kolicina,
        dopunska_teorijska_obuka_cena: this.dopunska_teorijska_obuka_cena,
        dopunska_teorijska_obuka_kolicina: this.dopunska_teorijska_obuka_kolicina,
        polaganje_teorijskog_ispita_cena: this.polaganje_teorijskog_ispita_cena,
        polaganje_teorijskog_ispita_kolicina: this.polaganje_teorijskog_ispita_kolicina,
        prakticna_obuka_cena: this.prakticna_obuka_cena,
        prakticna_obuka_kolicina: this.prakticna_obuka_kolicina,
        dodatna_prakticna_obuka_cena: this.dodatna_prakticna_obuka_cena,
        dodatna_prakticna_obuka_kolicina: this.dodatna_prakticna_obuka_kolicina,
        dopunska_prakticna_obuka_cena: this.dopunska_prakticna_obuka_cena,
        dopunska_prakticna_obuka_kolicina: this.dopunska_prakticna_obuka_kolicina,
        polaganje_prakticnog_ispita_cena: this.polaganje_prakticnog_ispita_cena,
        polaganje_prakticnog_ispita_kolicina: this.polaganje_prakticnog_ispita_kolicina,
      }
      if (typeof this.$route.params.id !== 'undefined' && this.$route.params.id !== 'kreiraj') {
        this.$http.put(`/autoskole-administratori/cene-obuke/${this.$route.params.id}`, data).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('SablonJeSnimljen'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      } else {
        this.$http.post('/autoskole-administratori/cene-obuke', data).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('SablonJeSnimljen'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
      }
    },
  },
}
</script>
